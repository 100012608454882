<script setup lang="ts">
import { Crisp } from 'crisp-sdk-web';


onMounted(() => {
  Crisp.chat.hide()
})
onBeforeUnmount(() => {
  Crisp.chat.show()
})

</script>

<template lang="pug">
v-app
  VitePwaManifest
  slot
</template>
